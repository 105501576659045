import { Text } from '@latitude/text';
import { Tooltip } from '@latitude/tooltip';
import { useMemo } from 'react';
import { PORTO_SELF_REGISTRATION_PAGES } from 'components/porto/Signup/helpers';
import css from './styles.module.scss';
type Props = {
  page: PORTO_SELF_REGISTRATION_PAGES;
};
function PageHeader({
  page
}: Props) {
  const {
    description,
    step,
    title
  } = useMemo(() => {
    switch (page) {
      case PORTO_SELF_REGISTRATION_PAGES.COMPLETE_YOUR_PROFILE:
        return {
          description: 'Start by entering a few personal details.',
          step: 1,
          title: 'Sign up for porto'
        };
      default:
        return {
          description: "Now, fill in your organization's information.",
          step: 2,
          title: 'Organization details'
        };
    }
  }, [page]);
  return <div className={css.container} data-sentry-component="PageHeader" data-sentry-source-file="PageHeader.tsx">
      <Text data-sentry-element="Text" data-sentry-source-file="PageHeader.tsx">Step {step} of 2</Text>
      <Text variant="headingLarge" className={css.notMobile} data-sentry-element="Text" data-sentry-source-file="PageHeader.tsx">
        {title}
      </Text>
      <Text variant="headingMedium" className={css.mobile} data-sentry-element="Text" data-sentry-source-file="PageHeader.tsx">
        {title}
      </Text>
      <Text variant="labelMedium" className="flex gap-0.5" data-sentry-element="Text" data-sentry-source-file="PageHeader.tsx">
        {description}
        <div className="max-w-fit">
          <Tooltip className="text-clip" side="bottom" text={<div className={css.tooltipContainer}>
                <Text variant="labelMedium" className={css.tooltipTextContainer}>
                  We collect this information to comply with relevant laws and
                  regulations. We only use it for business purposes and do not
                  share it for any other reason. Failure to provide legitimate
                  information may cause disruptions to the account.
                </Text>
              </div>} data-sentry-element="Tooltip" data-sentry-source-file="PageHeader.tsx">
            <div data-testid="tooltip-text">
              <Text variant="labelMedium" className={css.tooltipColor} data-sentry-element="Text" data-sentry-source-file="PageHeader.tsx">
                Why is this important?
              </Text>
            </div>
          </Tooltip>
        </div>
      </Text>
    </div>;
}
export default PageHeader;