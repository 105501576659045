import Head from 'next/head';
import React from 'react';
import PageContainer from './components/PageContainer/PageContainer';
import useIsPortoApp from 'utils/useIsPortoApp';
import css from './styles.module.scss';
const Signup = () => {
  const isPorto = useIsPortoApp();
  if (!isPorto) {
    return <></>;
  }
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="index.tsx">
        <title>Porto - Registration</title>
      </Head>
      <div className={css.page}>
        <PageContainer data-sentry-element="PageContainer" data-sentry-source-file="index.tsx" />
      </div>
    </>;
};
export default Signup;