import completeYourProfileImage from './images/complete-your-profile.svg';
import organizationDetails from './images/organization-details.svg';
import registrationComplete from './images/registration-complete.svg';
export enum PORTO_SELF_REGISTRATION_PAGES {
  COMPLETE_YOUR_PROFILE = 'COMPLETE_YOUR_PROFILE',
  ORGANIZATION_DETAILS = 'ORGANIZATION_DETAILS',
  REGISTRATION_COMPLETE = 'REGISTRATION_COMPLETE',
}
export enum PORTO_SELF_REGISTRATION_PAGE_TITLE {
  COMPLETE_YOUR_PROFILE = 'The self-custody wallet for institutions',
  ORGANIZATION_DETAILS = 'Transact ERC-20 tokens and connect to dApps',
  REGISTRATION_COMPLETE = 'View assets and history on web, transact with the Porto iOS app',
}
export const getPortoSelfRegistrationPageImage = (page: PORTO_SELF_REGISTRATION_PAGES) => {
  switch (page) {
    case PORTO_SELF_REGISTRATION_PAGES.ORGANIZATION_DETAILS:
      return organizationDetails;
    case PORTO_SELF_REGISTRATION_PAGES.REGISTRATION_COMPLETE:
      return registrationComplete;
    default:
      return completeYourProfileImage;
  }
};