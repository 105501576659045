import { Button } from '@latitude/button';
import cn from 'classnames';
import { PORTO_SELF_REGISTRATION_PAGES } from 'components/porto/Signup/helpers';
import css from './styles.module.scss';
type Props = {
  page: PORTO_SELF_REGISTRATION_PAGES;
  setPage: (page: PORTO_SELF_REGISTRATION_PAGES) => void;
  onSubmit: () => void;
  isDisabled: boolean;
  isLoading: boolean;
};
function PageButtons({
  page,
  setPage,
  onSubmit,
  isDisabled,
  isLoading
}: Props) {
  if (page === PORTO_SELF_REGISTRATION_PAGES.REGISTRATION_COMPLETE) {
    return null;
  }
  const isFirstPage = page === PORTO_SELF_REGISTRATION_PAGES.COMPLETE_YOUR_PROFILE;
  const showBackButton = !isFirstPage;
  const handleBackButton = () => {
    if (page === PORTO_SELF_REGISTRATION_PAGES.ORGANIZATION_DETAILS) {
      setPage(PORTO_SELF_REGISTRATION_PAGES.COMPLETE_YOUR_PROFILE);
    }
  };
  const handleContinueButton = () => {
    if (page === PORTO_SELF_REGISTRATION_PAGES.COMPLETE_YOUR_PROFILE) {
      setPage(PORTO_SELF_REGISTRATION_PAGES.ORGANIZATION_DETAILS);
    } else if (page === PORTO_SELF_REGISTRATION_PAGES.ORGANIZATION_DETAILS) {
      onSubmit();
    }
  };
  const isLastFormPage = page === PORTO_SELF_REGISTRATION_PAGES.ORGANIZATION_DETAILS;
  return <div className={cn({
    [css.container!]: true,
    [css.singleButton!]: !showBackButton,
    [css.doubleButton!]: showBackButton,
    [css.firstPageSingleButton!]: isFirstPage
  })} data-sentry-component="PageButtons" data-sentry-source-file="PageButtons.tsx">
      {showBackButton && <Button type="outline" onClick={handleBackButton} htmlType="button">
          Back
        </Button>}
      <Button htmlType="button" onClick={handleContinueButton} disabled={isDisabled} loading={isLastFormPage ? isLoading : false} data-sentry-element="Button" data-sentry-source-file="PageButtons.tsx">
        {isLastFormPage ? 'Submit' : 'Continue'}
      </Button>
    </div>;
}
export default PageButtons;