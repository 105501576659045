import { Text } from '@latitude/text';
import css from './styles.module.scss';
import RegistrationCompleteMobile from '../../../../images/registrationCompleteMobile';
const RegistrationCompleteContent = () => {
  return <div className={css.contentWrapper} data-sentry-component="RegistrationCompleteContent" data-sentry-source-file="RegistrationCompleteContent.tsx">
      <div className={css.content}>
        <RegistrationCompleteMobile className={css.illustration} data-sentry-element="RegistrationCompleteMobile" data-sentry-source-file="RegistrationCompleteContent.tsx" />
        <Text variant="metricHuge" className={css.pageTitle} data-sentry-element="Text" data-sentry-source-file="RegistrationCompleteContent.tsx">
          Onboarding request sent
        </Text>
        <Text data-sentry-element="Text" data-sentry-source-file="RegistrationCompleteContent.tsx">
          Thank you for submitting your request. We have received your details
          and will email you to arrange an onboarding session.
        </Text>
        <Text variant="labelMedium" data-sentry-element="Text" data-sentry-source-file="RegistrationCompleteContent.tsx">
          Need help? Feel free to reach out to us at{' '}
          <a href="mailto:help@porto.com" className={css.link}>
            help@porto.com
          </a>
          .
        </Text>
      </div>
    </div>;
};
export { RegistrationCompleteContent };