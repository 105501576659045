import { useState } from 'react';
import { PORTO_SELF_REGISTRATION_PAGES } from 'components/porto/Signup/helpers';
import css from './styles.module.scss';
import PageContent from '../PageContent/PageContent';
import PageSideBanner from '../PageSideBanner/PageSideBanner';
function PageContainer() {
  const [currentPage, setCurrentPage] = useState(PORTO_SELF_REGISTRATION_PAGES.COMPLETE_YOUR_PROFILE);
  return <div className={css.container} data-sentry-component="PageContainer" data-sentry-source-file="PageContainer.tsx">
      <div className={css.sideBannerContainer}>
        <PageSideBanner page={currentPage} data-sentry-element="PageSideBanner" data-sentry-source-file="PageContainer.tsx" />
      </div>
      <PageContent page={currentPage} setPage={setCurrentPage} data-sentry-element="PageContent" data-sentry-source-file="PageContainer.tsx" />
    </div>;
}
export default PageContainer;