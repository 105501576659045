import dynamic from 'next/dynamic';
import { Loader } from '@anchorage/common/dist/components';
import Signup from 'components/porto/Signup';
import useIsPortoApp from 'utils/useIsPortoApp';
import css from './styles.module.css';
const ModalWithNoSSR = dynamic(() => import('components/porto/StartModal'), {
  ssr: false,
  loading: () => <Loader className={css.loader} />
});
export default function Index() {
  const isPorto = useIsPortoApp();
  return isPorto ? <Signup data-sentry-element="Signup" data-sentry-component="Index" data-sentry-source-file="index.tsx" /> : <ModalWithNoSSR description="" notAllowedRoute data-sentry-element="ModalWithNoSSR" data-sentry-component="Index" data-sentry-source-file="index.tsx" />;
}